import queryString from 'query-string';

import { SearchFilterContentTypes } from '../../../Shared/enums';
import { AudioSelectedSearchFilterOptions } from '../../containers/MenuContainerInterfaces';
import {
  genres,
  moods,
  musicInstruments,
  sfxCategories,
} from '../../entities/AudioSearchFilterOptions';
import sharedSearchOptionsToQueryParams from './sharedSearchOptionsToQueryParams';
import { NewSearchLocation, SearchHistoryState } from './types';

export default function audioSearchOptionsToLocation(
  options: Partial<AudioSelectedSearchFilterOptions>
): NewSearchLocation {
  const state = {} as SearchHistoryState;

  const pathname = `/audio/search${
    options.searchTerm ? '/' + encodeURIComponent(options.searchTerm) : ''
  }`;

  const queryParams = sharedSearchOptionsToQueryParams(options) as any;

  switch (options.contentType) {
    case SearchFilterContentTypes.Music:
      queryParams['media-type'] = 'music';
      break;
    case SearchFilterContentTypes.Sound_effects:
      queryParams['media-type'] = 'sound-effects';
      break;
  }

  if (options.minDuration) {
    queryParams.min_duration = options.minDuration;
  }

  if (options.maxDuration) {
    queryParams.max_duration = options.maxDuration;
  }

  if (options.tempoMin) {
    queryParams.bpm_min = options.tempoMin;
  }

  if (options.tempoMax) {
    queryParams.bpm_max = options.tempoMax;
  }

  const categories = [
    ...(options.musicMoods || [])
      .map((id) => moods.find((m) => m.categoryId === id))
      .filter(Boolean)
      .map(({ urlId }) => urlId),
    ...(options.musicGenres || [])
      .map((id) => genres.find((g) => g.categoryId === id))
      .filter(Boolean)
      .map(({ urlId }) => urlId),
    ...(options.musicInstruments || [])
      .map((id) => musicInstruments.find((i) => i.categoryId === id))
      .filter(Boolean)
      .map(({ urlId }) => urlId),
    ...(options.sfxCategories || [])
      .map((id) => sfxCategories.find((c) => c.categoryId === id))
      .filter(Boolean)
      .map(({ urlId }) => urlId),
  ];
  if (categories.length > 0) {
    queryParams.categories = categories.join('_');
  }

  if (options.vocalType != null) {
    queryParams.has_vocals = options.vocalType;
  }

  if (options.artists?.length > 0) {
    queryParams.portal_artist_ids = options.artists
      .map(({ contributorPortalId }) => contributorPortalId)
      .join(',');
    state.artists = options.artists;
  }

  if (options.similarTo) {
    queryParams.search_similar_id = options.similarTo;
    state.searchSimilarTitle = options.searchSimilarTitle;
  }

  const query = queryString.stringify(queryParams, {
    skipNull: true,
    skipEmptyString: true,
  });

  return { url: `${pathname}${query ? `?${query}` : ''}`, state };
}
