export type User = {
  id: number;
  email: string;
  displayName: string;
};

export enum PlanClassification {
  LEGACY_PLAN_CLASSIFICATION = 'Legacy',
  BASIC_PLAN_CLASSIFICATION = 'Basic',
  STARTER_PLAN_CLASSIFICATION = 'Starter',
  BUNDLE_PLAN_CLASSIFICATION = 'Bundle',
  UNLIMITED_PLAN_CLASSIFICATION = 'Unlimited',
  ENTERPRISE_PLAN_CLASSIFICATION = 'Enterprise',
  GROWTH_PLAN_CLASSIFICATION = 'Growth',
  PRO_PLAN_CLASSIFICATION = 'Pro',
  BASIC_2023_PLAN_CLASSIFICATION = 'Basic (2023 Revamp)',
  PRO_2023_PLAN_CLASSIFICATION = 'Pro (2023 Revamp)',
}

export enum SubscriptionStatus {
  STATUS_ONHOLD = 'onhold',
  STATUS_ACTIVE = 'active',
  STATUS_CANCEL = 'cancel',
  STATUS_MANUAL = 'manual',
  STATUS_INVOICE = 'invoice',
  STATUS_DONOTBILL = 'donotbill',
  STATUS_ORGREQUESTED = 'requested',
  STATUS_ORGREJECTED = 'rejected',
}

export const SiteTypes = {
  Video: { value: 'video', siteId: 1 },
  Images: { value: 'images', siteId: 2 },
  Audio: { value: 'audio', siteId: 3 },
} as const;

export type SiteTypeKey = keyof typeof SiteTypes;
export type SiteTypeValue = (typeof SiteTypes)[SiteTypeKey]['value'];
export type SiteId = (typeof SiteTypes)[SiteTypeKey]['siteId'];

// UserSubscription.php
export type Subscription = {
  id: number;
  uniqueId: string;
  planId: number;
  organizationId: number;
  siteId: number;
  fullName: string;
  status: string;
  subscriptionEndDate: object;
  daysLeftOnSubscription: number;
  dateAdded: string;
  dateUpdated: string;
  isMakerOnly: boolean;
  planClassification: PlanClassification;
  rolloverPlanId?: number;
  monthlyDownloadsRemaining?: number;
  licenseName?: string;
};

export interface AuthReducerState {
  recaptchaToken?: string;
  userFullName: string;
  makerHooksVisible?: boolean;
  projects?: [];
  isMobile?: boolean;
  monthlyDownloadsRemaining?: number;
  isLoggedIn: boolean;
  isIpAuth: boolean;
  user: User;
  subscription: Subscription;
  primarySubscription: Subscription;
  videoSubscription: Subscription;
  audioSubscription: Subscription;
  imageSubscription: Subscription;
  permissions: { [key: string]: boolean };
  featureFlags: { [key: string]: boolean };
}
