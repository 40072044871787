import { createAction } from 'redux-actions';
import { push, replace } from 'redux-first-history';

import { SearchIdentifiers } from '@videoblocks/events-ts/lib/storyblocks/search/records/SearchIdentifiers';
import { Telemetry } from '@videoblocks/kafka-rest-client';

import { ReduxState } from '../../../AppLoader/types';
import events from '../../../Events/Events';
import SiteConstants from '../../../common/SiteConstants/SiteConstants';
import { getPageUrl } from '../../../common/utils';
import { searchOrigins } from '../../Shared/constants';
import SearchAPI from '../SearchAPI';
import { DrawerType, TYPES } from '../SearchTypes';
import { AnySearchFilterOptions } from '../containers/MenuContainerInterfaces';
import {
  selectPagination,
  selectSearchFilterOptions,
} from '../selectors/searchSelectors';
import {
  resetSearchOptionsIfAssetIdSearch,
  searchOptionsToLocation,
} from '../utils/searchOptionsToLocation';

export const ERROR_CODE_REQUEST_SUPERSEDED = 'ERROR_CODE_REQUEST_SUPERSEDED';

export const DRAWER_DEFAULT_ITEM_COUNT = 8;

export const fetchSearchResultsStarted = createAction(
  TYPES.FETCH_SEARCH_RESULTS_STARTED
);
export const fetchSearchResultsFailed = createAction(
  TYPES.FETCH_SEARCH_RESULTS_FAILED,
  (error) => error
);
export const fetchSearchResultsCompleted = createAction(
  TYPES.FETCH_SEARCH_RESULTS_COMPLETED
);

export const loadMoreSearchResultsCompleted = createAction(
  TYPES.LOAD_MORE_SEARCH_RESULTS_COMPLETED
);

export const fetchSearchResultsTotalCountsStarted = createAction(
  TYPES.FETCH_SEARCH_RESULTS_TOTAL_COUNTS_STARTED
);
export const fetchSearchResultsTotalCountsCompleted = createAction(
  TYPES.FETCH_SEARCH_RESULTS_TOTAL_COUNTS_COMPLETED
);
export const fetchSearchResultsTotalCountsFailed = createAction(
  TYPES.FETCH_SEARCH_RESULTS_TOTAL_COUNTS_FAILED,
  (error) => error
);

export function isSearchAppLoaded() {
  return Boolean(
    typeof document === 'object' &&
      document.getElementById('search-results-app')
  );
}

export function fetchSearchResults(
  selectedSearchFilterOptions: AnySearchFilterOptions,
  updateTotalResultsCountOnly = false
) {
  return (dispatch, getState: () => ReduxState) => {
    const { resultsPerPage } = selectPagination(getState());
    if (resultsPerPage) {
      selectedSearchFilterOptions = selectedSearchFilterOptions.update({
        resultsPerPage,
      });
    }
    if (updateTotalResultsCountOnly) {
      dispatch(
        fetchSearchResultsTotalCountsStarted(selectedSearchFilterOptions)
      );
    } else {
      dispatch(fetchSearchResultsStarted(selectedSearchFilterOptions));
    }
    const searchTimer = Telemetry.timer(
      `search.frontEnd.${
        selectedSearchFilterOptions.searchOrigin || searchOrigins.UNKNOWN
      }`
    );

    return SearchAPI.search(selectedSearchFilterOptions)
      .then((response) => response.json())
      .then((response) => {
        /**
         * Keep track of the most recent request made, to ensure that if concurrent requests are made,
         * only the most recent one is kept.
         *
         * This is a workaround, due to the fact that the fetch() API does not support aborting requests (yet)
         *
         * @see https://github.com/whatwg/fetch/issues/447
         */
        const lastHash = getState().app.search.lastRequestHash;
        const isNewestSearchRequest =
          lastHash === null || selectedSearchFilterOptions.hash() === lastHash;
        if (isNewestSearchRequest) {
          // Update the v3 search identifiers
          const searchIdentifiers = new SearchIdentifiers();
          searchIdentifiers.searchId = response.data && response.data.sslid;
          searchIdentifiers.searchPageId = response.data && response.data.sguid;
          events.setSearchIdentifiers(searchIdentifiers);

          return response;
        } else {
          const error = new Error('A request superseded this one');
          (error as any).code = ERROR_CODE_REQUEST_SUPERSEDED;
          return Promise.reject(error);
        }
      })
      .then((json) => {
        if (updateTotalResultsCountOnly) {
          dispatch(
            fetchSearchResultsTotalCountsCompleted(
              json.data.pagination.totalResults
            )
          );
        } else {
          SiteConstants.buildInstance();

          if (selectedSearchFilterOptions.loadMore) {
            dispatch(loadMoreSearchResultsCompleted(json.data));
          } else {
            dispatch(fetchSearchResultsCompleted(json.data));
          }
        }
      })
      .catch((e) => {
        if (updateTotalResultsCountOnly) {
          dispatch(fetchSearchResultsTotalCountsFailed(e));
        } else {
          dispatch(fetchSearchResultsFailed(e));
        }
      })
      .finally(searchTimer);
  };
}

export function toggleMobileFilterModalOpen(isOpen) {
  return (dispatch) => {
    dispatch(createAction(TYPES.TOGGLE_MOBILE_FILTER_MODAL_OPEN)(isOpen));
  };
}

export function toggleMobileSortModalOpen(isOpen) {
  return (dispatch) => {
    dispatch(createAction(TYPES.TOGGLE_MOBILE_SORT_MODAL_OPEN)(isOpen));
  };
}

const fetchCollapsedSetResultsStarted = createAction(
  TYPES.FETCH_COLLAPSED_SET_RESULTS_STARTED
);
const fetchCollapsedSetResultsCompleted = createAction(
  TYPES.FETCH_COLLAPSED_SET_RESULTS_COMPLETED
);

export function fetchCollapsedSetResults(selectedSearchFilterOptions) {
  return (dispatch) => {
    dispatch(fetchCollapsedSetResultsStarted());
    return SearchAPI.search(selectedSearchFilterOptions)
      .then((response) => response.json())
      .then((json) => dispatch(fetchCollapsedSetResultsCompleted(json)));
  };
}

export const stockItemSelected = createAction(TYPES.STOCK_ITEM_SELECTED);
export const setSelectedStockItemOptions = createAction(
  TYPES.SET_SELECTED_STOCK_ITEM_OPTIONS
);

export const toggleStockItemSelect = createAction(
  TYPES.STOCK_ITEM_SELECT_TOGGLE
);

export function setIsSideMenuOpened(isOpen) {
  return (dispatch) => {
    dispatch(createAction(TYPES.IS_SIDE_MENU_OPEN)(isOpen));
  };
}

export const setSearchOptions = createAction(TYPES.SET_SEARCH_OPTIONS);
export const setSearchOptionsAndUpdateFilters = createAction(
  TYPES.SET_SEARCH_OPTIONS_AND_UPDATE_FILTERS
);

export const expandMoreLikeThisDrawer = createAction<{
  initialItemId: number;
  drawerType?: DrawerType;
}>(TYPES.MORE_LIKE_THIS_DRAWER_EXPAND);
export const collapseMoreLikeThisDrawer = createAction(
  TYPES.MORE_LIKE_THIS_DRAWER_COLLAPSE
);

export function updateSearchOptionsAndRefresh(
  selectedSearchFilterOptions: Partial<AnySearchFilterOptions>,
  openSearchInNewWindow = false
) {
  return (dispatch, getState: () => ReduxState) => {
    // Get new location from selected filter options.
    // If we're now searching a different content class we need to do a full page request.
    // The search pages are the only places that support client-side routing,
    // so do a full page request if we're not on a search page now.
    selectedSearchFilterOptions = resetSearchOptionsIfAssetIdSearch(
      selectedSearchFilterOptions
    );

    const newLocation = searchOptionsToLocation(selectedSearchFilterOptions);

    const currentContentClass = selectSearchFilterOptions(
      getState()
    ).contentClass;
    const isCrossSiteSearch =
      selectedSearchFilterOptions.contentClass &&
      currentContentClass &&
      selectedSearchFilterOptions.contentClass !== currentContentClass;
    const needsFullPageRequest = !isSearchAppLoaded() || isCrossSiteSearch;

    if (openSearchInNewWindow) {
      window.open(newLocation.url);
    } else if (needsFullPageRequest) {
      window.location.href = newLocation.url;
    } else {
      dispatch(push(newLocation.url, newLocation.state));
    }
  };
}

export function initiateFetchWorkflow(
  selectedSearchFilterOptions: Partial<AnySearchFilterOptions>
) {
  return (dispatch) => {
    if (selectedSearchFilterOptions.page === 1) {
      // Instantly scroll to top for new searches
      window.scrollTo(0, 0);
    }
    dispatch(fetchSearchResults(selectedSearchFilterOptions, false));
    dispatch(setSearchOptionsAndUpdateFilters(selectedSearchFilterOptions));
    dispatch(collapseMoreLikeThisDrawer());
    dispatch(toggleMobileFilterModalOpen(false));
  };
}

export function updateTotalResultsCountForFilter(selectedSearchFilterOptions) {
  return (dispatch) => {
    dispatch(fetchSearchResults(selectedSearchFilterOptions, true));
  };
}

export function updateSearchPage(page: number, itemId: number) {
  const updatedUrl = getPageUrl(page, itemId);

  // Update current (most recent) URL to mark a specific page/item.
  return (dispatch) => {
    dispatch(replace(updatedUrl));
  };
}

export const getDrawerNumberItemsVisible = createAction(
  TYPES.SHOW_MORE_DRAWER_ITEMS
);

export function updateDrawerNumberItemsVisible(numDrawerItemsVisible) {
  return (dispatch) => {
    dispatch(getDrawerNumberItemsVisible(numDrawerItemsVisible));
  };
}

export const setLoadingForSearch = createAction(TYPES.SET_SEARCH_LOADING);

export const setShowMainMenu = createAction(TYPES.SHOW_MAIN_MENU);

export const setHideMainMenu = createAction(TYPES.HIDE_MAIN_MENU);

export const setShowSubMenu = createAction(TYPES.SHOW_SUB_MENU);

export function showSubMenu(name, groupName, inputType, allOptions, onChange) {
  return (dispatch) => {
    dispatch(
      setShowSubMenu({ name, groupName, inputType, allOptions, onChange })
    );
  };
}

export const setHideSubMenu = createAction(TYPES.HIDE_SUB_MENU);

export function hideSubMenu() {
  return (dispatch) => {
    dispatch(setHideSubMenu());
  };
}

export const clearSuggestionsQuery = createAction(
  TYPES.CLEAR_SUGGESTIONS_QUERY
);
