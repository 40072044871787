import events from '../Events/Events';
import OptimizeExperimentAssignmentEventFactory from '../Events/OptimizeExperimentAssignmentEventFactory';
import PageSpeedEventFactory from '../Events/PageSpeedEventFactory';
import StockItemClickEventFactory from '../Events/StockItemClickEventFactory';

class Logger {
  accessTelemetry() {
    return events.telemetry;
  }

  // Called by a Google Tag Manager snippet
  triggerPageSpeedEvent() {
    const pageSpeedEvent = PageSpeedEventFactory.create();
    if (pageSpeedEvent) {
      events.produce(pageSpeedEvent);
    }
  }

  /**
   * @param stockItem
   * @param {?number} position - The position of the stock item in the search page (if applicable)
   */
  produceDownloadClickEvent(stockItem, position = null) {
    const event = StockItemClickEventFactory.download(stockItem, position);
    events.produce(event, event.user.visitorCookieId);
  }

  produceDetailsClickEvent(stockItem, position = null) {
    const event = StockItemClickEventFactory.detailsClick(stockItem, position);
    events.produce(event, event.user.visitorCookieId);
  }

  error(err, data = {}) {
    console.log(err);
    console.log(data);
  }
}

// export singleton object so global session state can be shared
const loggerInstance = new Logger();
export default loggerInstance;
window.Logger = loggerInstance;
window.OptimizeExperimentAssignmentEventFactory =
  OptimizeExperimentAssignmentEventFactory;
if (window.dispatchEvent) {
  window.dispatchEvent(
    new Event('OptimizeExperimentAssignmentEventFactoryLoaded')
  );
}
