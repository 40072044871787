import { StockItem, StockItemDetails } from '../../common/types/StockItemTypes';
import { FilterInputType } from './components/menu/common/MenuEnums';
import { AnySearchFilterOptions } from './containers/MenuContainerInterfaces';

export type SelectedStockItem = {
  id: string;
  index: string;
  details: string;
  selectedFormatId: number;
  selectedFormatName?: string;
  selectedFormatResolution?: string;
  formatSelectorVisible: boolean;
  addSubscription: boolean;
};

export type RelatedSearch = {
  url: string;
  relatedSearchTerm: string;
};

export enum AbTests {}

export type SearchFeatures = {
  favoritesBinId?: string;
  bmtTestSegmentName: string;
  abTests?: Record<AbTests, boolean>;
  isMobile?: boolean;
  redirectToSignUpLink?: boolean;
  isEnterpriseMember?: boolean;
  isVbComplimentaryMember?: boolean;
  shouldShowAddToFavorites?: boolean;
  useSubscriptionlessSignUpEnticement?: boolean;
  canDownloadPreviews?: boolean;
  signUpLink?: string;
  downloadPermissions?: Record<string, boolean>;
  useSearchHeaderMicrofrontend?: boolean;
  // TODO: remove this when releasing search UI updates.
  useRemainingSearchUI?: boolean;
  isInThumbnailSimplifyExperiment?: boolean;
  isInVisitorDownloadModalExperiment?: boolean;
};

export interface SearchPaginationState {
  currentPageNumber: number;
  maxPageNumber: number;
  hasMoreResults: boolean;
  resultsPerPage: number;
  totalResults: number;
}

export interface SearchReducerState {
  sslid: string; // searchSessionLogId
  sguid: string;
  countryCode: string;
  portfolioInfo: any;
  categories: any;
  searchOptions: any;
  totalItemCountForContributor: number;
  username: string;
  features: SearchFeatures;
  shouldUpdateSearchFilters: boolean;
  selectedSearchFilterOptions: AnySearchFilterOptions;
  searchResultsTotalCountsForFilters: number;
  hideSearchResults: boolean;
  mobileFilterModalOpen: boolean;
  mobileSortModalOpen: boolean;
  collapsedSetStockItems: StockItem[]; // todo: StockItemDetails[]?
  collectionUrlName: boolean;
  context: string;
  drawerContentLoaded: boolean;
  drawerIsOpen: boolean;
  drawerNumberItemsVisible: number;
  drawerSelectedItemId: number;
  drawerType: DrawerType;
  favoritesBinId: boolean;
  isLoading: boolean;
  isLoadingAdditionalResults: boolean;
  isSideMenuOpened: boolean;
  lastRequestHash: number;
  menu: {
    isOpen: boolean;
    subMenu: {
      isOpen: boolean;
      name: string;
      groupName?: string;
      inputType: FilterInputType | string;
      allOptions?: [];
      onChange?: () => void;
    };
  };
  pagination: Partial<SearchPaginationState>;
  relatedSearch: RelatedSearch[];
  searchDescription: string;
  searchDescriptionWithoutSearchTerm: string;
  searchHistory: object;
  searchLogParameters: object;
  searchTerm: string;
  selectedStockItem: SelectedStockItem;
  shouldShowAddToFavorites: boolean;
  stockItems: StockItemDetails[];
  url: string;
  canonicalLink: string;
  pageHeading: string;
  pendingRequestHash: string;
  statusCode: string;
  suggestionsQuery: string;
}

export enum DrawerType {
  ALT_VERSIONS = 'altVersions',
  MORE_LIKE_THIS = 'moreLikeThis',
  RECOMMENDED_MUSIC = 'recommendedMusic',
}

export const TYPES = {
  SET_SEARCH_OPTIONS: 'SET_SEARCH_OPTIONS',
  SET_SEARCH_OPTIONS_AND_UPDATE_FILTERS:
    'SET_SEARCH_OPTIONS_AND_UPDATE_FILTERS',
  FETCH_SEARCH_RESULTS_STARTED: 'FETCH_SEARCH_RESULTS_STARTED',
  FETCH_SEARCH_RESULTS_FAILED: 'FETCH_SEARCH_RESULTS_FAILED',
  FETCH_SEARCH_RESULTS_COMPLETED: 'FETCH_SEARCH_RESULTS_COMPLETED',
  LOAD_MORE_SEARCH_RESULTS_COMPLETED: 'LOAD_MORE_SEARCH_RESULTS_COMPLETED',
  FETCH_SEARCH_RESULTS_TOTAL_COUNTS_STARTED:
    'FETCH_SEARCH_RESULTS_TOTAL_COUNTS_STARTED',
  FETCH_SEARCH_RESULTS_TOTAL_COUNTS_FAILED:
    'FETCH_SEARCH_RESULTS_TOTAL_COUNTS_FAILED',
  FETCH_SEARCH_RESULTS_TOTAL_COUNTS_COMPLETED:
    'FETCH_SEARCH_RESULTS_TOTAL_COUNTS_COMPLETED',
  STOCK_ITEM_SELECTED: 'STOCK_ITEM_SELECTED',
  SET_SELECTED_STOCK_ITEM_OPTIONS: 'SET_SELECTED_STOCK_ITEM_OPTIONS',
  IS_SIDE_MENU_OPEN: 'IS_SIDE_MENU_OPEN',
  STOCK_ITEM_SELECT_TOGGLE: 'STOCK_ITEM_SELECT_TOGGLE',
  MORE_LIKE_THIS_DRAWER_EXPAND: 'MORE_LIKE_THIS_DRAWER_EXPAND',
  MORE_LIKE_THIS_DRAWER_COLLAPSE: 'MORE_LIKE_THIS_DRAWER_COLLAPSE',
  FETCH_COLLAPSED_SET_RESULTS_STARTED: 'FETCH_COLLAPSED_SET_RESULTS_STARTED',
  FETCH_COLLAPSED_SET_RESULTS_COMPLETED:
    'FETCH_COLLAPSED_SET_RESULTS_COMPLETED',
  SHOW_MORE_DRAWER_ITEMS: 'SHOW_MORE_DRAWER_ITEMS',
  SET_SEARCH_LOADING: 'SET_SEARCH_LOADING',
  SHOW_MAIN_MENU: 'SHOW_MAIN_MENU',
  HIDE_MAIN_MENU: 'HIDE_MAIN_MENU',
  SHOW_SUB_MENU: 'SHOW_SUB_MENU',
  HIDE_SUB_MENU: 'HIDE_SUB_MENU',
  TOGGLE_MOBILE_FILTER_MODAL_OPEN: 'TOGGLE_MOBILE_FILTER_MODAL_OPEN',
  TOGGLE_MOBILE_SORT_MODAL_OPEN: 'TOGGLE_MOBILE_SORT_MODAL_OPEN',
  CHANGE_CRUMBS: 'CHANGE_CRUMBS',
  CLEAR_SUGGESTIONS_QUERY: 'CLEAR_SUGGESTIONS_QUERY',
};
