import { createSelector } from '@reduxjs/toolkit';

import { ReduxState } from '../AppLoader/types';
import { ContentClass } from '../app/Shared/enums';
import {
  PlanClassification,
  SiteTypeKey,
  SiteTypes,
  SiteTypeValue,
  SubscriptionStatus,
} from './AuthTypes';

export const selectAuthPermissions = (state: ReduxState) =>
  state.auth.permissions;

export const selectAuthFeatureFlags = (state: ReduxState) =>
  state.auth.featureFlags;

export const selectUseSearchHeaderMicrofrontend = (state: ReduxState) =>
  state.auth.featureFlags.useSearchHeaderMicrofrontend;

export const selectIsLoggedIn = (state: ReduxState) => state.auth.isLoggedIn;

export const selectIsIpAuth = (state: ReduxState) => state.auth.isIpAuth;

export const selectPrimarySubscription = (state: ReduxState) =>
  state.auth.primarySubscription;

export const selectMonthlyDownloadsRemaining = (state: ReduxState) =>
  state.auth.monthlyDownloadsRemaining;

export const selectIsMobile = (state: ReduxState) => state.auth.isMobile;

export const selectHasVideoSubscription = (state: ReduxState) =>
  state.auth.videoSubscription;

export const selectHasAudioSubscription = (state: ReduxState) =>
  state.auth.audioSubscription;

export const selectHasImagesSubscription = (state: ReduxState) =>
  state.auth.imageSubscription;

export const selectHasAnySubscription = (state: ReduxState) => {
  const isLoggedIn = selectIsLoggedIn(state);

  return (
    isLoggedIn &&
    !!(
      state.auth.videoSubscription ||
      state.auth.audioSubscription ||
      state.auth.imageSubscription
    )
  );
};

export const selectHasSubscriptionForContentClass =
  (contentClass: ContentClass) => (state: ReduxState) => {
    const isLoggedIn = selectIsLoggedIn(state);
    const subscriptionMap = {
      [ContentClass.Video]: selectHasVideoSubscription,
      [ContentClass.Image]: selectHasImagesSubscription,
      [ContentClass.Audio]: selectHasAudioSubscription,
    };

    return isLoggedIn && subscriptionMap[contentClass](state);
  };

export const selectUser = (state: ReduxState) => state.auth.user;

export const selectProjects = (state: ReduxState) => state.auth.projects;

export const selectMakerHooksVisible = (state: ReduxState) =>
  state.auth.makerHooksVisible;

export const selectOrganizationId = (state: ReduxState) =>
  state.auth.subscription?.organizationId;

export const selectIsOrgUser = (state: ReduxState): boolean => {
  const organizationId = selectOrganizationId(state);
  return organizationId != null;
};

export const selectUserId = (state: ReduxState) => state.auth?.user?.id;

export const selectUserFullName = (state: ReduxState) =>
  state.auth.userFullName || state.auth.subscription.fullName;

export const selectUserSubscriptionUniqueId = (state: ReduxState) =>
  state.auth.subscription?.uniqueId;

export const selectPrimarySubscriptionEndDate = (state: ReduxState) =>
  state.auth.subscription.subscriptionEndDate;

export const selectSiteTypeValueBySiteId = (
  state: ReduxState
): SiteTypeValue => {
  //Find subscription if not a bundle customer
  const subscriptionToEvaluate =
    selectPrimarySubscription(state) || getNonBundleSubscription(state);
  const siteId = subscriptionToEvaluate?.siteId;

  const key = Object.keys(SiteTypes).find(
    (key) => SiteTypes[key as SiteTypeKey].siteId === siteId
  ) as SiteTypeKey | undefined;

  return key ? SiteTypes[key].value : undefined;
};

export const selectIsUserOnStarterPlan = createSelector(
  [selectPrimarySubscription],
  (subscription) =>
    subscription?.planClassification ==
    PlanClassification.STARTER_PLAN_CLASSIFICATION
);

export const selectIsUserOnGrowthPlan = createSelector(
  [selectPrimarySubscription],
  (subscription) =>
    subscription?.planClassification ==
    PlanClassification.GROWTH_PLAN_CLASSIFICATION
);

export const getNonBundleSubscription = (state: ReduxState) => {
  if (selectHasVideoSubscription(state)) {
    return state.auth.videoSubscription;
  } else if (selectHasAudioSubscription(state)) {
    return state.auth.audioSubscription;
  } else if (selectHasImagesSubscription(state)) {
    return state.auth.imageSubscription;
  }
};

export const selectIsUserCancelledOrOnHold = (state: ReduxState) => {
  //Find subscription if not a bundle customer
  const subscriptionToEvaluate =
    selectPrimarySubscription(state) || getNonBundleSubscription(state);
  if (subscriptionToEvaluate) {
    return (
      subscriptionToEvaluate?.status === SubscriptionStatus.STATUS_ONHOLD ||
      subscriptionToEvaluate?.status === SubscriptionStatus.STATUS_CANCEL
    );
  }

  //Default if no subscription found
  return false;
};

function getInitials(fullName: string): string {
  const trimmedName = fullName ? fullName.trim() : '';

  if (!trimmedName) {
    return '';
  }

  const names = trimmedName.split(' ');
  const initials = names.map((name) => name.charAt(0).toUpperCase());
  return initials.join('');
}

export const selectUserInitials = createSelector(
  [selectIsLoggedIn, selectUser],
  (isLoggedIn, user) => (isLoggedIn ? getInitials(user.displayName) : '')
);
